module.exports = {
    AT_HOME_ABROAD_CARD_PRODUCT_ALIAS: 'at_home_abroad_card',
    CURRENT_ACCOUNT_PRODUCT_ALIAS: 'current_account',
    DEPOSIT_PRODUCT_ALIAS: 'DEPOSIT',
    COMBINED_DEPOSIT_ALIAS: 'combined_deposits',
    COMBINED_DEPOSIT_CONTRACT_INFO_CMS_KEY: 'depositcombined',
    DEPOSIT_13_MONTHS_EXCEPTIONS_CONTRACT_INFO_CMS_KEY: 'deposit13mexception',
    DEPOSIT_13_MONTHS_EXCEPTIONS_HIRING_INFO_CMS_KEY: '13_months_deposits_Exception',
    DEPOSIT_13_MONTHS_RENEWAL_EXCEPTIONS_CONTRACT_INFO_CMS_KEY: 'deposit13mrenovationexception',
    DEPOSIT_13_MONTHS_RENEWAL_EXCEPTIONS_HIRING_INFO_CMS_KEY: '13mdeposits_renovation_exceptions',
    DEPOSIT_18_MONTHS_RETENTION_CMS_KEY: 'retention18',
    DEPOSIT_24_MONTHS_RETENTION_CMS_KEY: 'retention24',
    DEPOSIT_18_MONTHS_RETENTION_ALIAS: '18_months_retention_deposits',
    DEPOSIT_24_MONTHS_RETENTION_ALIAS: '24_months_retention_deposit',
    DEPOSIT_ACCOUNT_ALIAS: '13_months_deposits',
    DEPOSIT_18_MONTHS_ALIAS: '18_months_deposits',
    DEPOSIT_OPEN_EXCEPTION: 'DEPOSIT_OPEN_EXCEPTION',
    DEPOSIT_OPEN_RENOVATION: 'DEPOSIT_OPEN_RENOVATION',
    DIAMOND_PACK_PRODUCT_ALIAS: 'diamond_pack',
    DIAMOND_PACK_PRODUCT_ALIAS_CC: 'diamond_pack_cc',
    E_CARD_PRODUCT_ALIAS: 'e_card',
    MAIN_DEPOSIT: 'main_deposit',
    MINI_CARD_PRODUCT_ALIAS: 'mini_card',
    NERUDA_CARD_PRODUCT_ALIAS: 'nrd_opencredit',
    NERUDA_CARD_PRODUCT_ALIAS_CC: 'nrd_opencredit_cc',
    OPEN_CREDIT_CARD_PRODUCT_ALIAS: 'open_credit_card',
    OPEN_DEBIT_CARD_PRODUCT_ALIAS: 'open_debit_card',
    OPEN_YOUNG_ACCOUNT_PRODUCT_ALIAS: 'OPEN_YOUNG_ACCOUNT',
    OPEN_YOUNG_CARD_PRODUCT_ALIAS: 'open_young_card',
    OPEN_YOUNG_PACK_PRODUCT_ALIAS: 'open_young_pack',
    OPEN_YOUNG_PACK_PRODUCT_MIN_ALIAS: 'PACK_OPEN_YOUNG',
    PAYROLL_ACCOUNT_PRODUCT_ALIAS: 'PAYROLL_ACCOUNT',
    PAYROLL_UPGRADE_ACCOUNT_PRODUCT_ALIAS: 'PAYROLL_UPGRADE_ACCOUNT',
    PAYROLL_ACCOUNT_ALIAS: 'payroll_account',
    PREMIUM_PACK_PRODUCT_ALIAS: 'premium_pack',
    PREMIUM_PACK_PRODUCT_ALIAS_CC: 'premium_pack_cc',
    PREMIUM_PACK_NEW_PRODUCT_ALIAS_CC: 'Contrataciones_Paso_default_premiumpack_cc_1',
    PRODUCT_BANNER_COMPONENT_ID: 2,
    PRODUCT_HIRING_COMPONENT_ID: 3,
    PRODUCT_HIRED_DETAILS_COMPONENT_ID: 5,
    REVOLVING_CARD_PRDOUCT_ALIAS: 'revolving_card',
    SAVING_ACCOUNT_ALIAS: 'open_savings_account',
    SOLIDARIA_CARD_PRODUCT_ALIAS: 'solidaria_card',
    UPGRADE_TO_PAYROLL_ACCOUNT_ALIAS: 'upgrade_to_payroll_account',
    VANITY_CARD_PRODUCT_ALIAS: 'vanityfair',
    VANITY_CARD_PRODUCT_ALIAS_CC: 'vanityfair_cc',
    DIAMOND_CARD_PRODUCT_ALIAS: 'diamond',
    DIAMOND_CARD_NEW_PRODUCT_ALIAS_CC: 'Contrataciones_Paso_default_diamondpack_cc_1',
    OPEN_CREDIT_PRODUCT_ALIAS: 'visaopen',
    OPEN_CREDIT_PRODUCT_ALIAS_CC: 'visaopen_cc',
    HORMIGUERO_CARD_PRODUCT_ALIAS: 'ehcard',
    VIA_T_CARD_PRODUCT_ALIAS: 'via_t_card',
    VIA_T_CARD_PRODUCT_ALIAS_CC: 'via_t_card_cc',
    VIRTUAL_CREDIT_CARD_PRODUCT_ALIAS: 'virtual_credit_card',
    VIRTUAL_CREDIT_CARD_PRODUCT_ALIAS_CC: 'virtual_credit_card:_cc',
    VIRTUAL_DEBIT_CARD_PRODUCT_ALIAS: 'virtual_debit_card',
    WELCOME_ACCOUNT_ALIAS: 'SAVINGS_WELCOME_ACCOUNT',
    WELCOME_ACCOUNT_PRODUCT_ALIAS: 'BANKING_SAVING_WELCOME_ACCOUNT_CONTRACTUAL',
    WELCOME_ACCOUNT_PRECONTRACTUAL_PRODUCT_ALIAS:'BANKING_SAVING_WELCOME_ACCOUNT_PRECONTRACTUAL',
    WELCOME_PAYROLL_ACCOUNT_PRODUCT_ALIAS: 'WELCOME_PAYROLL_ACCOUNT',
    CHECKING_ACCOUNT_PRODUCT_ALIAS: 'CHECKING_ACCOUNT',
    E_ACCOUNT_LOAN_PRODUCT_ALIAS: 'SAVINGS_ACCOUNT',
    OPEN_DEBIT_CARD_PRODUCT_MIN_ALIAS: 'DEBIT_CARD',
    MAESTRO_CARD_PRODUCT_MIN_ALIAS: 'MAESTRO_CARD',
    METALLIC_CARD_PRODUCT_MIN_ALIAS: 'METALLIC_CARD',
    METALLIC_CARD_PRODUCT_ALIAS: 'metallic_card',
    MAESTRO_CARD_PRODUCT_ALIAS: 'maestro_card',
    R42_CARD_PRODUCT_MIN_ALIAS: 'R42_CARD',
    R42_CARD_PRODUCT_ALIAS: 'r42_card',
    BANKING_CHECKING_ACCOUNT_PRECONTRACTUAL: 'BANKING_CHECKING_ACCOUNT_PRECONTRACTUAL',
    BANKING_CHECKING_ACCOUNT_CONTRACTUAL: 'BANKING_CHECKING_ACCOUNT_CONTRACTUAL',
    BANKING_OPEN_YOUNG_ACCOUNT_PRECONTRACTUAL: 'BANKING_OPEN_YOUNG_ACCOUNT_PRECONTRACTUAL',
    BANKING_OPEN_YOUNG_ACCOUNT_CONTRACTUAL: 'BANKING_OPEN_YOUNG_ACCOUNT_CONTRACTUAL',
    BANKING_PAYROLL_ACCOUNT_PRECONTRACTUAL: 'BANKING_PAYROLL_ACCOUNT_PRECONTRACTUAL',
    BANKING_PAYROLL_ACCOUNT_CONTRACTUAL: 'BANKING_PAYROLL_ACCOUNT_CONTRACTUAL',
    BANKING_PAYROLL_UPGRADE_ACCOUNT_PRECONTRACTUAL: 'BANKING_PAYROLL_UPGRADE_ACCOUNT_PRECONTRACTUAL',
    BANKING_PAYROLL_UPGRADE_ACCOUNT_CONTRACTUAL: 'BANKING_PAYROLL_UPGRADE_ACCOUNT_CONTRACTUAL',
    BANKING_SAVING_ACCOUNT_CONTRACTUAL: 'BANKING_SAVING_ACCOUNT_CONTRACTUAL',
    BANKING_SAVING_ACCOUNT_PRECONTRACTUAL: 'BANKING_SAVING_ACCOUNT_PRECONTRACTUAL',
};
