import Immutable from "immutable";
import types from "./types"

const setInitialState = () => {
    return Immutable.fromJS({
        data: {},
        selectedOffers: [],
        loading: false,
        error: false,
        fetched: false,
        offersSelected: {},
        offersSelectedLoading: false,
        offersSelectedError: null,
        offersSelectedFetched: false
    });
}

const offers = (state = setInitialState(), action) => {
    switch (action.type) {
        case (types.SET_LOADING): {
            return state.merge(
                {
                    loading: action.payload,
                    data: {},
                    selectedOffers: []
                });
        }
        case (types.SET_ERROR): {
            return state.merge({ 
                error: action.payload,
                fetched: true,
                data: [],
                loading: false
            });
        }
        case (types.SET_DATA): {
            return state.merge({
                data: action.payload,
                error: false,
                fetched: true,
                loading: false,
            });
        }
        case (types.SET_LOADING_OFFERS_SELECTED): {
            return state.merge({
                offersSelectedLoading: action.payload
            });
        }
        case (types.SET_ERROR_OFFERS_SELECTED): {
            return state.merge({
                offersSelectedError: action.payload,
                offersSelectedFetched: true
            });
        }
        case (types.SET_DATA_OFFERS_SELECTED): {
            return state.merge({
                offersSelectedError: null,
                offersSelected: action.payload,
                offersSelectedFetched: true
            });
        }
        case (types.RESET): {
            return state.merge(setInitialState());
        }
        default: {
            return state;
        }
    }
};


export { offers };
