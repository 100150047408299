const { sortByOrder } = require('lodash/collection');
const { COUNTRIES_CODES: { DE, ES, PT, NL } } = require('constants/countries');

const ACCOUNTS = {
    '300800': 'C.C. OPERATIVA',
    '300801': 'C.C. OPERATIVA HBF',
    '300002': 'C.C. ESPECIAL',
    '300803': 'C.C. OPERATIVA DIVISAS',
    '300804': 'CUENTA DE DERIVADOS',
    '300805': 'CUENTA NÓMINA OPEN',
    '300806': 'CUENTA FÁCIL',
    '300807': 'CUENTA 123 OPENBANK',
    '301101': 'CUENTA DE AHORRO OPEN YOUNG',
    '301001': 'CUENTA OPEN',
    '301002': 'CUENTA AHORRO EMPRESA',
    '301003': 'CUENTA VIVIENDA',
    '301004': 'CUENTA OPEN PLUS',
    '301005': 'CUENTA MAS OPEN',
    '301006': 'CUENTA DE AHORRO OPENBANK',
    '301007': 'CUENTA BIENVENIDA',
    '301010': 'CUENTA BIENVENIDA NÓMINA',
    '100800': 'CRÉDITO INVERSIÓN',
    '100801': 'CRÉDITO MIGRACIÓN',
    '300': 'CUENTA CORRIENTE',
    '301': 'CUENTA AHORRO',
    '100': 'CRÉDITO INVERSIÓN',
    DEFAULT: ''
};

const ACCOUNTS_BY_COUNTRIES = {
    [NL]: {
        '300800': 'OPERATIONELE BANKREKENING',
        '300801': 'OPERATIONELE BANKREKENING HBF',
        '300002': 'SPECIALE BANKREKENING',
        '300803': 'OPERATIONELE VALUTA BANKREKENING',
        '300804': 'DERIVATIEVE REKENING',
        '300805': 'OPEN SALARISREKENING',
        '300806': 'EENVOUDIGE REKENING',
        '300807': 'OPENBANK 123 REKENING',
        '301101': 'OPEN YOUNG SPAARREKENING',
        '301001': 'OPEN REKENING',
        '301002': 'BEDRIJFSPAARREKENING',
        '301003': 'WONINGREKENING',
        '301004': 'OPEN PLUS REKENING',
        '301005': 'MEER OPEN REKENING',
        '301006': 'OPENBANK SPAARREKENING',
        '301007': 'WELKOM REKENING',
        '100800': 'INVESTERINGSKREDIET',
        '100801': 'MIGRATIEKREDIET',
        '300': 'LOPENDE REKENING',
        '301': 'SPAARREKENING',
        '100': 'INVESTERINGSKREDIET',
        '300814': 'OPEN BETAALREKENING',
        '301018': 'OPENBANK SPAARREKENING',
        '301019': 'WELKOM REKvENING',
        DEFAULT: ''
    },
    [DE]: {
        '300800': 'AKTIVES GIROKONTO',
        '300801': 'AKTIVES GIROKONTO HBF',
        '300002': 'GIROKONTO SPECIAL',
        '300803': 'AKTIVES GIROKONTO DEVISEN',
        '300804': 'DERIVATKONTO',
        '300805': 'GEHALTSKONTO OPEN',
        '300806': 'KONTO EASY',
        '300807': 'KONTO 123 OPENBANK',
        '301101': 'TAGESGELDKONTO OPEN YOUNG',
        '301001': 'KONTO OPEN',
        '301002': 'TAGESGELDKONTO FIRMA',
        '301003': 'IMMOBILIENKREDITKONTO',
        '301004': 'KONTO OPEN PLUS',
        '301005': 'KONTO MEHR OPEN',
        '301006': 'OPENBANK TAGESGELDKONTO',
        '301007': 'KONTO BIENVENIDA',
        '100800': 'ANLAGEKREDIT',
        '100801': 'MIGRATIONSKREDIT',
        '300': 'GIROKONTO',
        '301': 'TAGESGELDKONTO',
        '100': 'ANLAGEKREDIT',
        '300812': 'GIROKONTO',
        '301013': 'TAGESGELD',
        '301014': 'WILLKOMMEN TAGESGELD',
        DEFAULT: ''
    },
    [ES]: ACCOUNTS,
    [PT]: {
        '300800': 'C.C. OPERATIVA',
        '300801': 'C.C. OPERATIVA HBF',
        '300002': 'C.C. ESPECIAL',
        '300803': 'C.C. OPERATIVA DIVISAS',
        '300804': 'CONTA DE DERIVADOS',
        '300805': 'CONTA ORDENADO OPEN',
        '300806': 'CONTA FÁCIL',
        '300807': 'CONTA 123 OPENBANK',
        '301101': 'CONTA POUPANÇA OPEN YOUNG',
        '301001': 'CONTA OPEN',
        '301002': 'CONTA POUPANÇA EMPRESA',
        '301003': 'CONTA HABITAÇÃO',
        '301004': 'CONTA OPEN PLUS',
        '301005': 'CONTA MAS OPEN',
        '301006': 'CONTA POUPANÇA OPENBANK',
        '301007': 'CONTA DE BOAS-VINDAS',
        '100800': 'CRÉDITO INVESTIMENTO',
        '100801': 'CRÉDITO MIGRAÇÃO',
        '300': 'CONTA À ORDEM',
        '301': 'CONTA POUPANÇA',
        '100': 'CRÉDITO INVESTIMENTO',
        '300813': 'CONTA À ORDEM OPEN',
        '301016': 'CONTA POUPANÇA OPEN',
        '301017': 'CONTA DE BOAS-VINDAS',
        DEFAULT: ''
    }
}

const accountsOptions = function () {
    const accountsUnsorted = Object.keys(ACCOUNTS).map(key => {
        return {
            value: key,
            label: ACCOUNTS[key]
        };
    });

    return sortByOrder(accountsUnsorted, ['label'], ['asc']);
};

const ACCOUNTS_OPTIONS = accountsOptions();
const ACCOUNTS_WITH_CHEQUES_OR_DEBITS_SUBTYPES = [
    '800',
    '801',
    '802',
    '803',
    '805',
    '806',
    '807',
    '808',
    '812',
    '813',
    '814',
    '818'
];
const ACCOUNTS_FUTURES_TRANSFERS = ['800', '801', '803', '805', '807'];
const ACCOUNT_BRING_BANK_ACCOUNT_PRODUCT_ALIAS = 'transfer_account';
const ACCOUNT_BRING_BANK_ACCOUNT_UI_COMPONENT_ID = 3;

const WELCOME_ACCOUNT_FLOW = 'SAVINGS_WELCOME';
const SAVINGS_ACCOUNT_TYPE = '301';
const WELCOME_ACCOUNT_ID = 'welcome_account';
const WELCOME_ACCOUNT_TYPE = 'welcomeAccount';
const ACCOUNTS_FLOWS_MAP = {
    [WELCOME_ACCOUNT_FLOW]: WELCOME_ACCOUNT_TYPE,
    SAVINGS_WITH_CONDITION_WITHOUT_TERM: SAVINGS_ACCOUNT_TYPE,
    SAVINGS_WITH_CONDITION_WITH_TERM: SAVINGS_ACCOUNT_TYPE,
    SAVINGS_WITHOUT_CONDITION_WITH_TERM: SAVINGS_ACCOUNT_TYPE,
    SAVINGS_WITHOUT_CONDITION_WITHOUT_TERM: SAVINGS_ACCOUNT_TYPE,
    SAVINGS_CC_OFFERS_LANDING: SAVINGS_ACCOUNT_TYPE,
}
const WELCOME_ACCOUNT_HIRING_FLOW_URL = '/myprofile/hire/welcome-account/';

module.exports = {
    ACCOUNTS,
    ACCOUNTS_BY_COUNTRIES,
    ACCOUNTS_FLOWS_MAP,
    ACCOUNTS_OPTIONS,
    ACCOUNTS_WITH_CHEQUES_OR_DEBITS_SUBTYPES,
    ACCOUNTS_FUTURES_TRANSFERS,
    ACCOUNT_BRING_BANK_ACCOUNT_PRODUCT_ALIAS,
    ACCOUNT_BRING_BANK_ACCOUNT_UI_COMPONENT_ID,
    WELCOME_ACCOUNT_FLOW,
    WELCOME_ACCOUNT_HIRING_FLOW_URL,
    WELCOME_ACCOUNT_TYPE,
    WELCOME_ACCOUNT_ID
};
