require('./wizardError.scss');
const PropTypes = require('prop-types');
// @ vendors
const React = require('react');
const { withRouter } = require('react-router-dom');
const classnames = require('classnames');
const PureRenderMixin = require('react-addons-pure-render-mixin');
// @ commons
const { OKButton } = require('components/commons/OKButton/OKButton');
const OKTag = require('components/commons/OKTag/OKTag');
const { OKBoxTitle } = require('commonsComponents/OKBoxTitle');
// @ constants
const { recordProcess, PROC_FAIL } = require('utilities/tagging');
const { LINK_CLICK_ACTION_EVENT } = require("constants/tagging");
// @ utils
import { clickTaleStart, clickTaleEnd } from 'utilities/clickTale';
const { traceFlowEvent } = require('utilities/contactCenter/wrapUp/wrapUpCore')
const {
    OPERATION_LOG_TYPE_FINISH,
    OPERATION_LOG_FAIL,
    OPERATION_LOG_TYPE_INITIALIZE,
    OPERATION_LOG_TYPE_ENTRY
} = require('utilities/contactCenter/wrapUp/constants')
const { recordLink } = require("utilities/tagging");
const { isString } = require('utilities/stringHelper');

class WizardError extends React.Component {
    constructor(props) {
        super(props);

        this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
        this.handleMainButtonClick = this.handleMainButtonClick.bind(this);
        this.handleCancleButtonClick = this.handleCancleButtonClick.bind(this);
        this.handleRedirectToSecondaryButtonURL = this.handleRedirectToSecondaryButtonURL.bind(this);
    }

    componentDidMount() {
        const { notifyWrapUp, onComponentDidMountCallback, showTealium, isSecondVersion, errorMessage } = this.props;
        let processCustomData = {};

        if (!!this.props.processDetail) {
            processCustomData.processDetail = this.props.processDetail;
        }

        if (!!this.props.productName) {
            processCustomData.productName = this.props.productName;
        }

        if (!!this.props.descriptionError) {
            processCustomData.descriptionError = this.props.descriptionError;
        }

        if (!!this.props.categoryError) {
            processCustomData.categoryError = this.props.categoryError;
        }

        processCustomData = { ...processCustomData, ...this.props.tealium };

        if (__CONTACT_CENTER__ && notifyWrapUp) {
            const {errorMessage, errorSubMessage} = this.props;
            const optionalSubMessage = (typeof errorSubMessage === "string" || errorSubMessage instanceof String) ? ` : ${errorSubMessage}` : '';
            const completeError = errorMessage + optionalSubMessage;
            const operatioLogType = traceFlowEvent.getCurrentState() === OPERATION_LOG_TYPE_INITIALIZE ?
                OPERATION_LOG_TYPE_ENTRY : OPERATION_LOG_TYPE_FINISH
            traceFlowEvent.notify(operatioLogType, OPERATION_LOG_FAIL, completeError)
            traceFlowEvent.reset()
        }

        clickTaleStart();
        if (showTealium) {
            recordProcess(this.props.processType, PROC_FAIL, processCustomData, isSecondVersion);
        }
        onComponentDidMountCallback(errorMessage)
    }

    componentWillUnmount() {
        clickTaleEnd();
    }

    handleMainButtonClick() {
        const { history, onMainButtonClick, mainButton: {url, label}, processDetail, processDetailPrefix } = this.props;

        recordLink(processDetailPrefix ? 'contratar ' : '' + processDetail + ' error' || '', LINK_CLICK_ACTION_EVENT,isString(label)&& label.toLowerCase());
        return (onMainButtonClick) ? onMainButtonClick() : (url) ? history.push(url) : history.goBack();
    }

    handleCancleButtonClick() {
        const { history, onCancleButtonClick, cancleButton: {url}} = this.props;

        return (onCancleButtonClick) ? onCancleButtonClick() : (url) ? history.push(url) : history.goBack();
    }

    handleRedirectToSecondaryButtonURL() {
        const { history, onSecondaryButtonClick, secondaryButton: {url}} = this.props;

        return (onSecondaryButtonClick) ? onSecondaryButtonClick() : (url) ? history.push(url) : history.goBack();
    }

    render() {
        const {
            errorMessage,
            errorSubMessage,
            errorTagLabelSubMessage,
            customClassName,
            children,
            mainButton,
            secondaryButton,
            cancleButton,
            icon,
            fullWidthWizardStep,
            footerCustomClassName,
            mainButtonCustomModifier
        } = this.props;
        const classNames = classnames('wizard-error-step', customClassName, {
            'wizard-error-step-full-width' : fullWidthWizardStep
        });
        let myMainButton;
        let mySecondaryButton;
        let myCancleButton;
        let myButtons;
        let errorTag;

        if (mainButton.label) {
            const mainButtonModifier = mainButtonCustomModifier ? mainButtonCustomModifier : 'link-complementary';
            myMainButton = (
                <div className="wizard-error-step__main-button">
                    <OKButton
                        id="lnk_wizardErrorStep_mainButton"
                        modifier={mainButtonModifier}
                        onClick={this.handleMainButtonClick}
                    >
                        {mainButton.label}
                    </OKButton>
                </div>
            );
        }
        if (cancleButton.label) {
            myCancleButton = (
                <div className="wizard-error-step__cancle-button">
                    <OKButton
                        id="lnk_wizardErrorStep_cancleButton"
                        modifier="link-complementary"
                        onClick={this.handleCancleButtonClick}
                    >
                        {cancleButton.label}
                    </OKButton>
                </div>
            );
        }

        if (secondaryButton.label) {
            mySecondaryButton = (
                <div className="wizard-error-step__secondary-button">
                    <OKButton
                        id="lnk_wizardErrorStep_secondaryButton"
                        modifier="ghost"
                        onClick={this.handleRedirectToSecondaryButtonURL}
                    >
                        {secondaryButton.label}
                    </OKButton>
                </div>
            );
        }

        if (myMainButton || mySecondaryButton || myCancleButton) {
            const footerClassname = classnames('wizard-error-step__footer', footerCustomClassName);
            myButtons = (
                <div className={footerClassname}>
                    <div className="wizard-error-step__separator" />
                    <div className="wizard-error-step__foot-buttons">
                       {myCancleButton} {myMainButton} {mySecondaryButton}
                    </div>
                </div>
            );
        }

        if (!!errorTagLabelSubMessage) {
            errorTag = (
                <div className="wizard-error-step__error-tag-label-message">
                    <OKTag content={errorTagLabelSubMessage} type="warning" emphasis={true} context="arrow"/>
                </div>
            )
        }

        return (
            <div className={classNames}>
                <div className="wizard-error-step__highlight">
                    <i className={`wizard-error-step__icon wizard-error-step__${icon}-icon`} >
                    </i>
                    <div className="wizard-error-step__messages">
                        <OKBoxTitle value={errorMessage}/>
                        <div className="wizard-error-step__error-sub-message">
                            {errorSubMessage}
                            {errorTag}
                        </div>
                    </div>
                </div>
                <div className="wizard-error-step__content">
                    {children}
                </div>
                {myButtons}
            </div>
        );
    }
}

WizardError.defaultProps = {
    mainButton: {},
    processDetail: null,
    processDetailPrefix: true,
    processType: null,
    productName: null,
    secondaryButton: {},
    cancleButton: {},
    categoryError: '',
    customClassName: '',
    footerCustomClassName: '',
    mainButtonCustomModifier: null,
    descriptionError: '',
    icon: 'error',
    fullWidthWizardStep: false,
    notifyWrapUp: true,
    showTealium: true,
    onComponentDidMountCallback: () => {},
    isSecondVersion: false,
    tealium: {},
};

WizardError.propTypes = {
    categoryError: PropTypes.string,
    descriptionError: PropTypes.string,
    errorMessage: PropTypes.string,
    errorSubMessage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    errorTagLabelSubMessage: PropTypes.string,
    customClassName: PropTypes.string,
    footerCustomClassName: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    mainButton: PropTypes.object,
    mainButtonCustomModifier: PropTypes.string,
    processDetail: PropTypes.string,
    processDetailPrefix: PropTypes.bool,
    processType: PropTypes.string,
    productName: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    secondaryButton: PropTypes.object,
    cancleButton: PropTypes.object,
    onMainButtonClick: PropTypes.func,
    onSecondaryButtonClick: PropTypes.func,
    onCancleButtonClick: PropTypes.func,
    icon: PropTypes.oneOf(['error', 'info']),
    fullWidthWizardStep: PropTypes.bool,
    notifyWrapUp: PropTypes.bool,
    onComponentDidMountCallback: PropTypes.func,
    showTealium: PropTypes.bool,
    history: PropTypes.object.isRequired,
    isSecondVersion: PropTypes.bool,
    tealium: PropTypes.object,
};

module.exports = withRouter(WizardError);
