import { CONTRACT_INDEX_DEPOSIT_EXPIRATION, CONTRACT_INDEX_DEPOSIT_RENEWAL, CONTRACT_INDEX_DEPOSIT_TRANSFER } from 'constants/index'

export const RENEWAL_DEPOSIT_FLOW = "/myprofile/purchase/depositrenewal";
export const EXPIRED_DEPOSIT_RENEWAL_FLOW = "/myprofile/purchase/depositexpired";
export const DEPOSITS_RENEWAL_SCHEDULED = 'DEPOSITS_RENEWAL_SCHEDULED';
export const RENEWAL_DEPOSIT_HIRING_FLOW = 'RENEWAL_DEPOSIT_HIRING_FLOW';
export const RENEWAL_DEPOSIT_HIRING_FLOW_POST_EXPIRATION = 'RENEWAL_DEPOSIT_HIRING_FLOW_POST_EXPIRATION';
export const POST_EXPIRATION = 'POST_EXPIRATION';
export const RENEWAL_OFFERS_LANDING = 'RENEWAL_OFFERS_LANDING';
export const EXPIRED_RENEWAL_OFFERS_LANDING = "EXPIRED_RENEWAL_OFFERS_LANDING";
export const DEPOSITS_SUMMARY_PAGE = "DEPOSITS_SUMMARY_PAGE";
export const DEPOSITS_FLOW_IDS = [
    RENEWAL_DEPOSIT_HIRING_FLOW,
    RENEWAL_OFFERS_LANDING,
    DEPOSITS_RENEWAL_SCHEDULED,
    RENEWAL_DEPOSIT_HIRING_FLOW_POST_EXPIRATION,
    EXPIRED_RENEWAL_OFFERS_LANDING
];
export const DEPOSITS_RENEWALS_AVAILABLE_FLOWS = [
    RENEWAL_DEPOSIT_HIRING_FLOW,
    RENEWAL_OFFERS_LANDING,
    RENEWAL_DEPOSIT_HIRING_FLOW_POST_EXPIRATION,
    EXPIRED_RENEWAL_OFFERS_LANDING
];
export const DEPOSIT_PRECONTRACTUAL_TYPE = {
    [CONTRACT_INDEX_DEPOSIT_RENEWAL]: 'DEPOSIT_RENEWAL',
    [CONTRACT_INDEX_DEPOSIT_EXPIRATION]: 'DEPOSIT_RENEWAL_EXPIRED',
    [CONTRACT_INDEX_DEPOSIT_TRANSFER]: 'DEPOSIT'
}
export const LAST_DAY_AMOUNT = 1;
export const LAST_DAY_AMOUNT_POST = 0;
export const BANNER_DEPOSIT_PAYROLL_ID = 'depo_payroll';
export const BANNER_DEPOSIT_WITHOUT_NEW_BALANCE_ID = 'depo_withoutNewBalance';
