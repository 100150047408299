export const SAVINGS_WITHOUT_CONDITION_WITHOUT_TERM = 'SAVINGS_WITHOUT_CONDITION_WITHOUT_TERM';
export const SAVINGS_WITHOUT_CONDITION_WITH_TERM = 'SAVINGS_WITHOUT_CONDITION_WITH_TERM';
export const SAVINGS_WITH_CONDITION_WITH_TERM = 'SAVINGS_WITH_CONDITION_WITH_TERM';
export const SAVINGS_WITH_CONDITION_WITHOUT_TERM = 'SAVINGS_WITH_CONDITION_WITHOUT_TERM'
export const SAVINGS_CC_OFFERS_LANDING = 'SAVINGS_CC_OFFERS_LANDING'
export const SAVING_ACCOUNTS_FLOWS = [
    SAVINGS_WITHOUT_CONDITION_WITHOUT_TERM,
    SAVINGS_WITHOUT_CONDITION_WITH_TERM,
    SAVINGS_WITH_CONDITION_WITHOUT_TERM,
    SAVINGS_WITH_CONDITION_WITH_TERM,
    SAVINGS_CC_OFFERS_LANDING,
];
export const BASE_IMG_URL = 'https://www.openbank.es/assets/static/private-assets/images/commercial-banners/';
export const SAVINGS_ACCOUNT_IMG = 'cuenta_ahorro.jpg';
export const SAVINGS_ACCOUNT_PASSPORT_IMG = 'cuenta_ahorro_pasaporte.jpg';
export const SAVINGS_ACCOUNT_ID = 'cuenta_ahorro_open';
export const SAVINGS_ACCOUNT_HIRING_FLOW_URL = '/myprofile/purchase/e-account-loan/';
export const SAVINGS_TITLE_MAP = {
    [SAVINGS_WITHOUT_CONDITION_WITHOUT_TERM]: "savingsAccounts-savingsAccounts_SBSPLabel",
    [SAVINGS_WITHOUT_CONDITION_WITH_TERM]: "savingsAccounts-savingsAccounts_SBCPLabel",
    [SAVINGS_WITH_CONDITION_WITHOUT_TERM]: "savingsAccounts-savingsAccounts_CBSPLabel",
    [SAVINGS_WITH_CONDITION_WITH_TERM]: "savingsAccounts-savingsAccounts_CBCPLabel"
}
export const SAVINGS_TAGGING_MAP = {
    [SAVINGS_WITHOUT_CONDITION_WITHOUT_TERM]: "sin bonificacion y sin plazo",
    [SAVINGS_WITHOUT_CONDITION_WITH_TERM]: "sin bonificacion y con plazo",
    [SAVINGS_WITH_CONDITION_WITHOUT_TERM]: "con bonificacion y sin plazo",
    [SAVINGS_WITH_CONDITION_WITH_TERM]: "con bonificacion y con plazo"
}
export const SAVINGS_PRODUCT_TYPE = "301";
export const SAVINGS_ACCOUNT_LANDING_OFFERS_URL = '/myprofile/accounts/savings';
export const SAVINGS_ACCOUNT_DROPDOWN_TITLE = 'banner301-title';
