// Use code 11 for all services until real coded is confirmed
module.exports = {
    messages: {
        ACCOUNT_BUY_AMAZON_GIFT_CARD_ID: 34,
        AT_HOME_ABROAD_AND_GDPR: 14,
        BROKER_HIRE_INVESTMENT_FUND_AND_GDPR: 10,
        BROKER_HIRE_PENSION_PLAN_AND_GDPR: 11,
        BROKER_STOCK_CONTRACT_AND_GDPR: 9,
        CHARITY_CARD_DATA_DETAILS: 28,
        CHECKING_ACCOUNT_AND_GDPR: 18,
        CODIGO_MESSAGE_1: 1,
        CODIGO_MESSAGE_2: 2,
        CODIGO_MESSAGE_4: 4,
        CODIGO_MESSAGE_5: 5,
        CONTRACT_VIAT_AND_GDPR: 16,
        DEPOSIT_EIGHTEEN_MONTHS_AND_GDPR: 21,
        DEPOSIT_THIRTEEN_MONTHS_AND_GDPR: 21,
        DEPOSIT_EIGHTEEN_MONTHS_RETENTION_AND_GDPR: 21,
        DEPOSIT_NM_AND_GDPR: 21,
        DEPOSIT_WNM_AND_GDPR: 21,
        DEPOSIT_RENEWAL_AND_GDPR: 21,
        DEPOSIT_TWENTYFOUR_MONTHS_RETENTION_AND_GDPR: 21,
        DIAMOND_PACK_AND_GDPR: 15,
        ECARD_HIRE_AND_GDPR: 13,
        EXTERNAL_TRANSFER_FUNDS_AND_GDPR: 12,
        E_ACCOUNT_AND_GDPR: 17,
        HIRE_CARD_OPEN_YOUNG: 55,
        HIRE_CHARITY_GDPR: 39,
        HIRE_CHARITY_NO_GDPR: 40,
        MAESTRO_AND_GDPR: 14,
        METALLIC_AND_GDPR: 14,
        MINI_CARD_HIRE_AND_GDPR: 14,
        MORTGAGE_REQUEST_CONFIRM_HOLDER_DATA_AND_GDPR: 25,
        NERUDA_HIRE_AND_GDPR: 15,
        OPEN_DEBIT_AND_GDPR: 14,
        OPERATION_EASY_GDPR: 26,
        OPERATION_PAYROLL_GDPR: 45,
        OPERATION_PAYROLL_NO_GDPR: 50,
        OPERATION_RETAIL_GDPR: 46,
        OPERATION_RETAIL_NO_GDPR: 54,
        PACK_OPEN_YOUNG_HIRE: 38,
        PAYROLL_ACCOUNT_AND_GDPR: 19,
        PREMIUM_PACK_AND_GDPR: 15,
        REVOLVING_HIRE_AND_GDPR: 15,
        VIRTUAL_CREDIT_HIRE_AND_GDPR: 15,
        VIRTUAL_ONLY_DEBIT_AND_GDPR: 14,
        VISA_OPEN_HIRE_AND_GDPR: 15,
    },
    ACCESS_KEY_CHANGE: 52,
    ACCOUNT_BUY_AMAZON_GIFT_CARD_PURCHASE_ID: 188,
    ACCOUNT_CANCEL_CHECKS_ID: 14,
    ACCOUNT_CANCEL_CHEQUEBOOK_ID: 13,
    ACCOUNT_DEBIT_EDIT: 50,
    ACCOUNT_DIRECT_DEBIT_ID: 168,
    ACCOUNT_INTERVENER_REMOVE_ID: 11,
    ACCOUNT_NEW_CHECKBOOK_ID: 12,
    ACCOUNT_OPEN_YOUNG_OPERATION_CODE: 80,
    ACCOUNT_PURCHASE_PAYMENT: 157,
    ADD_BENEFICIARY_ID: 11,
    ADD_DEPOSIT_IMPOSITION: 15,
    ADD_NEW_SHIPPING_ADDRESS: 171,
    ADD_NO_SEPA_BENEFICIARY_ID: 58,
    ADD_SEPA_BENEFICIARY_ID: 6,
    AMAZON_GIFT_CARD_RETURN: 245,
    AT_HOME_ABROAD: 102,
    AUTHORIZATION_MODIFY_ACCOUNT: 150,
    BLOCK_TRUSTED_DEVICE_SECURITY_CODE: 243,
    NEW_BLOCK_TRUSTED_DEVICE_SECURITY_CODE: 253,
    BROKER_ADD_SUBSCRIPTION_INVESTMENT_FUND_ID: 26,
    BROKER_DERIVATIVE_TRANSFER_ID: 95,
    BROKER_ETF_BUY_ID: 34,
    BROKER_FUND_ORDER_CANCEL: 25,
    BROKER_HIRE_INVESTMENT_FUND_ID: 71,
    BROKER_HIRE_PENSION_PLAN_ID: 73,
    BROKER_INTERNAL_TRANSFER_FUND_ID: 28,
    BROKER_ORDER_CANCEL: 32,
    BROKER_ORDER_CONDITIONED_CANCEL: 33,
    BROKER_PLAN_CANCEL_CONTRIBUTION_ID: 89,
    BROKER_PLAN_CONTRIBUTION_ID: 29,
    BROKER_PLAN_PERIODIC_CONTRIBUTION_EDIT_ID: 90,
    BROKER_PLAN_PERIODIC_CONTRIBUTION_ID: 93,
    BROKER_REIMBURSE_FUND_ID: 27,
    BROKER_RIGHTS_BUY_ID: 40,
    BROKER_RIGHTS_CASH_EXCHANGE_ID: 43,
    BROKER_RIGHTS_SELL_ID: 40,
    BROKER_STOCKS_ADVANCED_BUY_ID: 35,
    BROKER_STOCKS_BUY_ID: 34,
    BROKER_STOCK_CONTRACT_ID: 69,
    BROKER_TRANSFER_EXTERNAL_ID: 72,
    BROKER_TRANSFER_PENSION_PLAN_EXTERNAL: 192,
    BROKER_TRANSFER_PENSION_PLAN_INTERNAL: 178,
    BROKER_TRANSFER_VALUES_PARTIAL_ID: 36,
    BROKER_TRANSFER_VALUES_TOTAL_ID: 37,
    BROKER_WARRANTS_ADVANCED_BUY_ID: 35,
    CANCEL_CONTRIBUTION_MANAGMENT: 200,
    CANCEL_CREDIT_CARD_RENEWAL: 223,
    CANCEL_POST_PAYMENT_HISTORY: 222,
    CANCEL_POST_PAYMENT_TODAY: 205,
    CARD_ACTIVATION_ID: 22,
    CARD_BLOCK_REQUEST_ID: 17,
    CARD_CANCEL_DEFER_PURCHASE_SIGNATURE_PATTERN_TYPE: 226,
    CARD_CANCEL_PAY_LATER_ID: 121,
    CARD_CVV_REQUEST_ID: 105,
    CARD_DECREASE_LIMIT_ID: 20,
    CARD_DEPOSIT_MONEY_ID: 21,
    CARD_DOWNLOAD_PDF_ID: 105, // same as CVV
    CARD_GET_ECARD_PDF_SERVICE_CODE: 4,
    CARD_INCREASE_LIMIT_ID: 100,
    CARD_MOBILE_RECHARGE: 18,
    CARD_MODIFY_DEBIT_LIMITS_ID: 183,
    CARD_OPEN_YOUNG_CREDIT_OPERATION_CODE: 122,
    CARD_OPEN_YOUNG_OPERATION_CODE: 77,
    CARD_PAYMENT_CHANGE_ID: 76,
    CARD_PAYMENT_PURCHASE_DEFERRAL_ID: 78,
    CARD_PAY_LATER_ID: 24,
    CARD_PIN_CHANGE: 10,
    CARD_PIN_REQUEST_ID: 3,
    CARD_REPLACE_ID: 16,
    CARD_SECURE_PAYMENT_ID: 23,
    CARD_TRANSFER_MONEY_ID: 103,
    CHANGE_ALL_CONSENT: 162,
    CHARITY_CARD_HIRE: 179,
    CHARITY_CARD_MODIFY: 180,
    CHECKING_ACCOUNT: 154,
    CONTRACT_EASY_LOAN_ID: 124,
    CONTRACT_VIAT: 216,
    CREDIT_CARD_MODIFY_ASSOCIATED_ACCOUNT: 204,
    CREDIT_PREGRANTED_CANCEL: 30,
    CREDIT_PREGRANTED_VARIATION_LIMIT: 31,
    DEBIT_CANCELLATION_NEXT_PAYMENTS: 49,
    DEBIT_REACTIVATION_NEXT_PAYMENTS: 149,
    DEBIT_RETURN: 48,
    DEPOSIT_EIGHTEEN_MONTHS: 81,
    DEPOSIT_RENEWAL: 256,
    DEPOSIT_NM: 81,
    DEPOSIT_WNM: 81,
    DEPOSIT_THIRTEEN_MONTHS: 81,
    DEPOSIT_EIGHTEEN_MONTHS_RETENTION: 81,
    DEPOSIT_TWENTYFOUR_MONTHS_RETENTION: 81,
    DIAMOND_PACK_CREDIT: 104,
    DIAMOND_PACK_DEBIT: 102,
    ECARD_CHARGE_ID: 7,
    ECARD_DISCHARGE_ID: 8,
    ECARD_HIRE: 77,
    ECOMMERCE_MODIFY_ID: 233,
    ECOMMERCE_REQUEST_ID: 234,
    EDIT_NO_SEPA_BENEFICIARY_ID: 59,
    EDIT_SEPA_BENEFICIARY_ID: 5,
    EXTERNAL_TRANSFER_FUNDS: 115,
    EXTERNAL_TRANSFER_VALUES_ID: 192,
    E_ACCOUNT: 79,
    GDPR_ACCESS_ID: 164,
    GDPR_ID: 163,
    GDPR_PORTABLE_ID: 165,
    HOME_INSURANCE_CONFIRMATION_ID: 170,
    HIRE_NEW_CREDIT_CARD_PACK_AND_GDPR: 15,
    LOAN_MODIFY_ASSOCIATED_ACCOUNT: 98,
    LOAN_PARTIAL_AMORTIZATION_CONFIRMATION: 99,
    MAESTRO: 102,
    METALLIC: 102,
    MINI_CARD_HIRE: 102,
    MORTGAGE_REQUEST_APPRAISAL_REQUEST: 1,
    MORTGAGE_REQUEST_CONFIRM_EXPENSES_PROVISION: 114,
    MORTGAGE_REQUEST_CONFIRM_HOLDER_DATA: 113,
    MORTGAGE_REQUEST_SIMPLE_NOTE: 1,
    MULTIPLE_OPERATIONS: 129,
    NERUDA_HIRE: 104,
    HIRE_NEW_CREDIT_CARD_FIRST_CODE: 104,
    HIRE_NEW_CREDIT_CARD_SECOND_CODE: 102,
    HIRE_NEW_CREDIT_CARD_THIRD_CODE: 216,
    OPEN_DEBIT: 102,
    OPEN_YOUNG_UPDATE_PROFILE: 185,
    PASS_MONEY_ID: 56,
    PAYROLL_ACCOUNT: 155,
    PIN_MAILING_ID: 19,
    PREMIUM_PACK_CREDIT: 104,
    PREMIUM_PACK_DEBIT: 102,
    PROFILE_EDITOR_UPDATE: 4,
    PROMOTION_SET_CODE: 112,
    REMOVE_BENEFICIARY_ID: 60,
    REMOVE_SCHEDULED_TRANSFER_ID: 63,
    REQUIRE_SCA: 231,
    REVOLVING_HIRE: 104,
    SECURITY_KIT_CHANGE: 153,
    SEND_SIGNATURE_KEY: 242,
    SHIPPING_ADDRESS_EDIT: 173,
    SHIPPING_ADDRESS_REMOVE: 172,
    SIGNATURE_KEY_CHANGE: 53,
    SIGNATURE_KEY_CHANGE_LOW_SCORE: 255,
    SIGNATURE_OPERATION_TYPE: 1,
    SOLIDARIA_CARD: 102,
    TRAVEL_PLUS_HIRE: 230,
    TAX_AND_RENTS_PAYMENT_WITH_DRAFT_ID: 55,
    TPP_KEY_OPERATION_TYPE_READBLE_REGISTER: 196,
    TPP_KEY_OPERATION_TYPE_REGISTER: 195,
    TPP_KEY_OPERATION_TYPE_REMOVE: 198,
    TPP_KEY_OPERATION_TYPE_RENEW: 197,
    TRANSFER_DEFERRED_EDITION_ID: 61,
    TRANSFER_IMMEDIATE: 169,
    TRANSFER_NONE_SEPA_DEFERRED_ID: 66,
    TRANSFER_NONE_SEPA_ID: 57,
    TRANSFER_PERIODIC_EDITION_ID: 62,
    TRANSFER_SEPA_INTERNATIONAL_DEFERRED_ID: 65,
    TRANSFER_SEPA_INTERNATIONAL_ID: 2,
    TRANSFER_SEPA_INTERNATIONAL_PERIODICITY_ID: 68,
    TRANSFER_SEPA_NATIONAL_DEFERRED_ID: 64,
    TRANSFER_SEPA_NATIONAL_ID: 1,
    TRANSFER_SEPA_NATIONAL_PERIODICITY_ID: 67,
    TRUST_ON_DEVICE: 199,
    VIRTUAL_CREDIT_HIRE: 104,
    VIRTUAL_ONLY_DEBIT_HIRE: 102,
    VISA_OPEN_HIRE: 104,
};
