// @constants
const {
    CREDIT,
    OPEN_DEBIT_CARD,
    OPEN_YOUNG,
    PAYROLL_ACCOUNT,
    SOLIDARITY_CARD_CONTRACT,
    MAESTRO_CARD,
    METALLIC_CARD,
    E_CARD
} = require('constants/flagBasedFeatures');

const {
    AT_HOME_ABROAD_CARD_PRODUCT_ALIAS,
    CURRENT_ACCOUNT_PRODUCT_ALIAS,
    DEPOSIT_ACCOUNT_ALIAS,
    DEPOSIT_18_MONTHS_ALIAS,
    DIAMOND_PACK_PRODUCT_ALIAS,
    E_CARD_PRODUCT_ALIAS,
    MINI_CARD_PRODUCT_ALIAS,
    OPEN_CREDIT_CARD_PRODUCT_ALIAS,
    OPEN_DEBIT_CARD_PRODUCT_ALIAS,
    OPEN_YOUNG_ACCOUNT_PRODUCT_ALIAS,
    OPEN_YOUNG_CARD_PRODUCT_ALIAS,
    OPEN_YOUNG_PACK_PRODUCT_ALIAS,
    PAYROLL_ACCOUNT_ALIAS,
    PREMIUM_PACK_PRODUCT_ALIAS,
    REVOLVING_CARD_PRDOUCT_ALIAS,
    SAVING_ACCOUNT_ALIAS,
    SOLIDARIA_CARD_PRODUCT_ALIAS,
    VIA_T_CARD_PRODUCT_ALIAS,
    VIRTUAL_CREDIT_CARD_PRODUCT_ALIAS,
    VIRTUAL_DEBIT_CARD_PRODUCT_ALIAS,
    MAESTRO_CARD_PRODUCT_ALIAS,
    METALLIC_CARD_PRODUCT_ALIAS
} = require('constants/hiringProductInformation');

const PRODUCT_HIRING_BANNER_DETAILS = {
    [DIAMOND_PACK_PRODUCT_ALIAS]: {
        sidebarImageDetails: {
            sidebarImgAlt: "bannerDiamondPack-title",
            sidebarImgId: "B.501.002.003",
            sidebarExtraImgId: "B.506.008.003",
        },
        link: '/myprofile/hire/card/diamond-pack',
        featureTogglingKey: CREDIT
    },
    [PREMIUM_PACK_PRODUCT_ALIAS]: {
        sidebarImageDetails: {
            sidebarImgAlt: "bannerPremiumPack-title",
            sidebarImgId: "B.501.003.003",
            sidebarExtraImgId: "B.506.009.003",
        },
        link: '/myprofile/hire/card/premium-pack',
        featureTogglingKey: CREDIT,
    },
    [OPEN_CREDIT_CARD_PRODUCT_ALIAS]: {
        sidebarImageDetails: {
            sidebarImgAlt: "banner500801-title",
            sidebarImgId: "B.500.801.012"
        },
        link: '/myprofile/hire/card/open-credit',
        featureTogglingKey: CREDIT,
    },
    [OPEN_DEBIT_CARD_PRODUCT_ALIAS]: {
        sidebarImageDetails: {
            sidebarImgAlt: "banner506002-title",
            sidebarImgId: "B.506.002.020",
            sidebarImgIdByCountry: {
                "es": "B.506.002.020",
                "de": "B.506.013.012",
                "nl": "B.506.014.017",
                "pt": "B.506.015.012"
            },
            sidebarImgIdByCountryStandardOff: {
                "de": "B.506.013.007",
                "nl": "B.506.014.017",
                "pt": "B.506.015.007"
            },
        },
        link: '/myprofile/hire/card/open-debit',
        featureTogglingKey: OPEN_DEBIT_CARD
    },
    [MAESTRO_CARD_PRODUCT_ALIAS] : {
        sidebarImageDetails: {
            sidebarImgAlt: "bannerMaestro-title",
            sidebarImgId: "B.506.019.012",
            sidebarImgIdByCountry: {
                "nl": "B.506.019.012"
            },
            sidebarImgIdByCountryStandardOff: {
                "nl": "B.506.019.007"
            },
        },
        link: '/myprofile/hire/card/maestro',
        featureTogglingKey: MAESTRO_CARD
    },
    [METALLIC_CARD_PRODUCT_ALIAS] : {
        sidebarImageDetails: {
            sidebarImgAlt: "bannerMetallic-title",
            sidebarImgId: "B.506.20.002"
        },
        link: '/myprofile/hire/card/metallic',
        featureTogglingKey: METALLIC_CARD
    },
    [SOLIDARIA_CARD_PRODUCT_ALIAS]: {
        sidebarImageDetails: {
            sidebarImgAlt: "banner506011-title",
            sidebarImgId: "B.506.011.001"
        },
        link: '/myprofile/hire/card/solidarity',
        featureTogglingKey: SOLIDARITY_CARD_CONTRACT
    },
    [REVOLVING_CARD_PRDOUCT_ALIAS]: {
        sidebarImageDetails: {
            sidebarImgAlt: "banner500003-title",
            sidebarImgId: "B.500.003.001"
        },
        link: '/myprofile/hire/card/revolving',
        featureTogglingKey: CREDIT,
    },
    [MINI_CARD_PRODUCT_ALIAS]: {
        sidebarImageDetails: {
            sidebarImgAlt: "banner506007-title",
            sidebarImgId: "B.506.007.002"
        },
        link: '/myprofile/hire/card/minicard',
        featureTogglingKey: CREDIT,
    },
    [AT_HOME_ABROAD_CARD_PRODUCT_ALIAS]: {
        sidebarImageDetails: {
            sidebarImgAlt: "banner506005-title",
            sidebarImgId: "B.506.005.001"
        },
        link: '/myprofile/hire/card/at-home-abroad',
        featureTogglingKey: CREDIT
    },
    [OPEN_YOUNG_CARD_PRODUCT_ALIAS]: {
        sidebarImageDetails: {
            sidebarImgAlt: "banner506010-title",
            sidebarImgId: "B.506.010.001"
        },
        link: '/myprofile/hire/card/open-young',
        featureTogglingKey: OPEN_YOUNG,
    },
    [VIA_T_CARD_PRODUCT_ALIAS]: {
        sidebarImageDetails: {
            sidebarImgAlt: "banner532001-title",
            sidebarImgId: "B.532.001.001",
        },
        link: '/myprofile/hire/card/via-t',
        featureTogglingKey: CREDIT,
    },
    [E_CARD_PRODUCT_ALIAS]: {
        sidebarImageDetails: {
            sidebarImgAlt: "banner506003-title",
            sidebarImgId: "B.506.003.002"
        },
        link: '/myprofile/hire/card/e-card',
        featureTogglingKey: E_CARD
    },
    [VIRTUAL_DEBIT_CARD_PRODUCT_ALIAS]: {
        sidebarImageDetails: {
            sidebarImgAlt: "banner506012-title",
            sidebarImgId: "B.506.012.001"
        },
        link: '/myprofile/hire/card/virtual-only-debit',
        featureTogglingKey: OPEN_YOUNG
    },
    [VIRTUAL_CREDIT_CARD_PRODUCT_ALIAS]: {
        sidebarImageDetails: {
            sidebarImgAlt: "bannerVirtualCredit-bannerVirtualCredit_title",
            sidebarImgId: "B.500.803.001"
        },
        link: '/myprofile/hire/card/virtual-credit',
        featureTogglingKey: CREDIT,
    },
    [OPEN_YOUNG_PACK_PRODUCT_ALIAS]: {
        sidebarImageDetails: {
            sidebarImgAlt: 'bannerOpenYoungPack-bannerOpenYoungPack_cardTitle',
            sidebarImgId: 'B.506.010.001'
        },
        link: '/myprofile/hire/pack/open-young',
        featureTogglingKey: OPEN_YOUNG,
    },
    [CURRENT_ACCOUNT_PRODUCT_ALIAS]: {
        sidebarImageDetails: {
            sidebarImgAlt: "banner300-title"
        },
        link: '/myprofile/purchase/checking-account'
    },
    [OPEN_YOUNG_ACCOUNT_PRODUCT_ALIAS]: {
        sidebarImageDetails: {
            sidebarImgAlt: "banner301101-title"
        },
        link: '/myprofile/purchase/junior-account',
        featureTogglingKey: OPEN_YOUNG
    },
    [PAYROLL_ACCOUNT_ALIAS]: {
        sidebarImageDetails: {
            sidebarImgAlt: "banner300805-title",
        },
        link: '/myprofile/purchase/payroll-account',
        featureTogglingKey: PAYROLL_ACCOUNT,
    },
    [SAVING_ACCOUNT_ALIAS]: {
        sidebarImageDetails: {
            sidebarImgAlt: "banner301-title"
        },
        link: '/myprofile/purchase/e-account-loan'
    },
    [DEPOSIT_ACCOUNT_ALIAS]: {
        sidebarImageDetails: {
            sidebarImgAlt: "bannerDeposit13-title"
        },
        link: '/myprofile/purchase/deposit-thirteen-months'
    },
    [DEPOSIT_18_MONTHS_ALIAS]: {
        sidebarImageDetails: {
            sidebarImgAlt: "bannerDeposit18-title"
        },
        link: '/myprofile/purchase/deposit-eighteen-months'
    }
}

module.exports = {
    PRODUCT_HIRING_BANNER_DETAILS
}
